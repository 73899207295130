var t = require('i18n').t
var CustomEvent = require('../constants/customEvent')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.ObjectController.extend({
    needs: ['user'],

    customEvent: Ember.inject.service(),

    segment: Em.inject.service(),

    dispatchCustomEvents: function() {
        var self = this

        this.get('model.associations').forEach(function(balanceModifier) {
            var subjectId = balanceModifier.get('subject.id')
            var subjectType = balanceModifier.get('subject.type')
            self.get('customEvent').dispatchEvent(CustomEvent.PaymentUpdated, { subjectId: subjectId, subjectType: subjectType })
        })
    },

    actions: {
        void: function() {
            var self = this
            var model = self.get('model')

            this.container.lookup('util:dialog')
                .confirmWarning(null, t('bank_payment.confirm_void'), t('bank_payment.yes_void'), t('no'))
                .then(function() {
                    model
                        .save({
                            properties: {
                                isVoided: true
                            }
                        })
                        .mask()
                        .on('success', function() {
                            self.get('segment').track('Bill Payment Voided (Client)')
                            self.dispatchCustomEvents()
                            self.container.lookup('util:notification').notify(NOTIFICATION_KEYS.BANK_PAYMENT_VOID, t('bank_payment.payment_voided_successfully'))
                        })
                        .on('error', function() {
                            if (typeof model.error === 'string') {
                                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.BANK_PAYMENT_VOID, model.error)
                            }
                        })
                })
        }
    }
})
