var functionProxy = require('function-proxy')

module.exports = Em.Mixin.create({
    scrollSelector: null,

    threshold: 400,

    itemHeight: 50,

    _infiniteScrollEl: null,

    _infiniteHeightDummy: null,

    _setupInfiniteScroll: function() {
        this._infiniteScrollEl = this.$(this.get('scrollSelector'))
        this._infiniteScrollEl.on('scroll', functionProxy(this._infiniteScrollDidScroll, this))
        this.get('controller').on('infiniteScrollItemsAdded', this, this._infiniteScrollScheduleDidScroll)

        this._infiniteHeightDummy = $('<div class="infinite-scroll-height-dummy"></div>')
        this._infiniteHeightDummy.appendTo(this.$(this.get('scrollSelector')))
        this._adjustInfiniteHeightDummy()

        this._showMoreIfNecessary()
    }.on('didInsertElement'),

    _infiniteScrollDidScroll: function() {
        if (this.get('isDestroying')) {
            return
        }
        if (this._hasRequestedAnimationFrame) {
            return
        }
        this._hasRequestedAnimationFrame = true
        window.requestAnimationFrame(this._showMoreIfNecessary.bind(this))
    },

    _showMoreIfNecessary: function() {
        if (this.get('isDestroying')) {
            return
        }

        this._hasRequestedAnimationFrame = false

        var self = this

        if (!this._infiniteScrollEl || !this._infiniteHeightDummy) {
            return
        }

        var ctTop = this._infiniteScrollEl.offset() && this._infiniteScrollEl.offset().top
        var ctHeight = this._infiniteScrollEl.outerHeight()
        var dummyTop = this._infiniteHeightDummy.offset().top
        if (dummyTop - (ctTop + ctHeight) < this.get('threshold')) {
            Em.run.join(self.get('controller'), 'send', 'showMore')
        }
    },

    _infiniteScrollContentDidChange: function() {
        Em.run.scheduleOnce('afterRender', this, this._infiniteScrollDidScroll)
        Em.run.scheduleOnce('afterRender', this, this._adjustInfiniteHeightDummy)
    }.observes('controller.scrollContent.length'),

    _adjustInfiniteHeightDummy: function() {
        if (!this._infiniteHeightDummy) {
            return
        }
        var remaining = this.get('controller.content.length') - this.get('controller.scrollContent.length')
        var height = remaining * this.get('itemHeight')
        this._infiniteHeightDummy.css('height', height + 'px')
    }
})
