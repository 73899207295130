var t = require('i18n').t
var _ = require('lodash')
var batmask = require('batmask')
var NOTIFICATION_KEYS = require('../notificationKeys')
var annualReportNavigation = require('../mixins/annual-report-navigation')
var annualReportPurchasing = require('../mixins/annual-report-purchasing')
var BILLING_TYPES = require('../billingTypes')
var ADDONS = require('../utils/addons')
var isCompanyEmv = require('../utils/isCompanyEmv')

module.exports = Em.Controller.extend(annualReportNavigation, annualReportPurchasing, {
    needs: ['user'],

    cvr: Em.inject.service(),

    annualReportCredits: Em.inject.service(),

    organizationSubscription: Em.inject.service(),

    user: function() {
        return this.container.lookup('controller:user')
    }.property(),

    isReportingAvailable: Em.computed.alias('model.commitment.isAvailable'),

    commitmentYear: Em.computed.alias('model.commitment.year'),

    billingCardSubscription: Em.inject.service(),

    organization: Em.computed.alias('controllers.user.activeOrganization'),

    showSubscriptionInfo: false, // whether to show AR Subscription related informations, combines AR year && subscription presence (see route)

    hasARSubscription: false, // from route, determines if AR subscription is bought

    hasARSubscriptionRenewed: false, // from route, if AR subscription present, was it already renewed?

    source: 'start',

    cvrCompanyTypeShort: function() {
        var cvrCompanyTypeShort = this.get('cvr.data.companyType')
        return isCompanyEmv(cvrCompanyTypeShort) ? 'emv' : 'ltd'
    }.property(),

    fiscalYear: function() {
        var annualReportService = this.get('annualReport')
        var organization = this.get('organization')

        return annualReportService.getFiscalYearForYear(
            this.get('commitmentYear'),
            organization.get('firstFiscalYearStart'),
            organization.get('firstFiscalYearEnd')
        )
    }.property('commitmentYear', 'organization.{firstFiscalYearStart,firstFiscalYearEnd}'),

    reportingAvailableFromDate: function() {
        var fiscalYearEndDate = this.get('fiscalYear.endDate')

        return fiscalYearEndDate.clone().add(1, 'day').format('LL')
    }.property('fiscalYear.endDate'),

    isInvalidRegistrationNo: function() {
        return this.get('annualReport').isInvalidRegistrationNo()
    }.property('organization.registrationNo'),

    adjustedBasePrice: function() {
        var annualReportService = this.get('annualReport')
        return annualReportService.getDisplayPriceAccountedForCoupons(this.get('basePrice'), 'diy')
    }.property('basePrice'),

    adjustedBasePriceOneoff: function() {
        var annualReportService = this.get('annualReport')
        return annualReportService.getDisplayPriceAccountedForCoupons(this.get('basePriceOneoff'), 'diy')
    }.property('basePriceOneoff'),

    hasUnusedCredits: function() {
        return this.get('annualReportCredits.hasUnusedCredits')
    }.property(),

    adjustedAccountingPackagePrice: function() {
        var annualReportService = this.get('annualReport')
        return annualReportService.getDisplayPriceAccountedForCoupons(this.get('accountingPackagePrice'), 'accounting_package')
    }.property('accountingPackagePrice'),

    saveAndContinue: function() {
        var year = this.get('model.commitment.year')

        var self = this
        var registrationNo = this.get('cvr').cleanRegistrationNo()

        this.get('annualReport')
            .commit(year, registrationNo)
            .then(function() {
                self.send('navigateToNextStep')
            })
            .catch(function(e) {
                switch (e.code) {
                case 'E_VALIDATION':
                    if (!(_.includes(['company', 'proprietorship'], self.get('organization.companyType')))) {
                        self.transitionToRoute('annual_report.not_applicable', year)
                    }

                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_SAVE, t('annual_report.request.validation_error'))
                    break
                }
            })
    },

    updateSubscription: function(plan, period, addons, couponCode, price) {
        var self = this
        var organizationSubscription = this.get('organizationSubscription')
        this.set('error', null) // reset error

        return organizationSubscription.ensureLoaded()
            .then(function() {
                return organizationSubscription.editSubscription(plan, period, addons, couponCode, price)
            })
            .then(function() {
                return organizationSubscription.load() // reload subscription
            })
            .catch(function(e) {
                self.set('error', e.message)
                throw e
            })
    },

    actions: {
        save: function() {
            this.saveAndContinue()
        },

        goToSettings: function() {
            this.container.lookup('router:main').transitionTo('settings')
        }
    },

    purchaseAccountingViaUPODI: function() {
        var self = this
        var billingCardSubscription = this.get('billingCardSubscription')
        var packageName = 'accountingPackage'
        batmask.maskDelayed()

        var url = window.location.href
        var continueUrl = url + (url.indexOf('?') > 0 ? '&' : '?') + 'package_bought=' + packageName

        var accountingAddon = ADDONS.ACCOUNTING_PACKAGE
        accountingAddon.year = this.get('fiscalYear.year')

        billingCardSubscription.purchaseAddons([accountingAddon], continueUrl)
            .then(function(purchaseResult) {
                if (!purchaseResult.paymentRequired) {
                    self.set('hasBoughtPackage', packageName)
                    batmask.unmask()
                }
            })
            .catch(function() {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.ADDON_PURCHASE_FAILED, t('validation.error'))
                batmask.unmask()
            })
    },

    purchaseAccountingViaOldFlow: function() {
        var self = this
        var organizationId = this.get('organization.id')
        var packageName = 'accountingPackage'

        var url = ('/quickpay/organizations/' + organizationId + '/purchase')
        var continueUrl = new URL(location.href)
        continueUrl.searchParams.append('hasBoughtPackage', packageName)

        self.get('api').post(url, {
            payload: {
                productKey: packageName,
                continueUrl: continueUrl.href,
                cancelUrl: location.href,
                language: self.get('user.quickpayLanguage')
            }
        })
            .then(function(res) {
                if (res.data.paymentRedirectUrl) {
                    window.location = res.data.paymentRedirectUrl
                } else {
                    self.set('hasBoughtPackage', packageName)
                }
            })
            .catch(function() {
                self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.AR_ACCOUNTING_PURCHASE, t('annual_report.buy_error'))
            })
            .finally(function() {
                batmask.unmask()
            })
    },

    customActions: {
        purchaseDIY: function() {
            this.saveAndContinue()
        },
        navigate: function(e) {
            this.transitionToRoute(e.detail.route)
        },
        purchaseAccountingPackage: function() {
            var organizationBillingType = this.get('organization.billingType')
            var isAllowedForUpodiMigration = this.get('organization.isAllowedForUpodiMigration')

            if (organizationBillingType === BILLING_TYPES.EXTERNAL || isAllowedForUpodiMigration) {
                return this.purchaseAccountingViaUPODI()
            }

            this.purchaseAccountingViaOldFlow()
        },

        editSubscription: function(e) {
            var self = this

            return self.updateSubscription(e.detail.plan, e.detail.period, undefined, e.detail.coupon, e.detail.price)
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.SUBSCRIPTION_UPDATE, t('changes_saved'))
                })
        }
    }
})
