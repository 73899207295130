var NOTIFICATION_KEYS = {
    ACCOUNT_DELETE: 'account-delete',
    ACCOUNT_POSTINGS_CLICK: 'account-postings-click',
    ACCOUNTS_IMPORT_FILE_UPLOAD: 'accounts-import-file-upload',
    ADDON_PURCHASE_FAILED: 'addon-purchase-failed',
    AIS_DEBT_COLLECTION_SEND: 'ais-debt-collection-send',
    AIS_TERMS_PROMPT: 'ais-terms-prompts',
    API_INTEGRATION_REQUEST: 'api-integration-request',
    API_PUT: 'api-put',
    API_V2_REQUEST: 'api-v2-request',
    APP_STORE_APP_INSTALL: 'app-store-app-install',
    APP_STORE_APP_UNINSTALL: 'app-store-app-uninstall',
    APP_STORE_AUTH: 'app-store-auth',
    AR_ACCOUNTING_PURCHASE: 'ar-accounting-purchase',
    AR_ANSWER: 'ar-answer',
    AR_APPROVE_BOOKKEEPING_APPROVE: 'ar-approve-bookkeeping-approve',
    AR_CHECKLIST_CHOOSE: 'ar-checklist-choose',
    AR_COMMIT: 'ar-commit',
    AR_INFO_APPROVE: 'ar-info-approve',
    AR_SAVE: 'ar-save',
    AR_STATE: 'ar-state',
    AR_STEPS: 'ar-steps',
    ATTACHMENT_ADD: 'attachment-add',
    BANK_CONNECTION_CONNECT: 'bank-connection-connect',
    BANK_IMPORT_ABORT: 'bank-import-abort',
    BANK_IMPORT_START: 'bank-import-start',
    BANK_INTEGRATION_DISCONNECT: 'bank-integration-disconnect',
    BANK_LINES_LOAD: 'bank-lines-load',
    BANK_LINES_MATCH_UNAPPROVE: 'bank-lines-match-unapprove',
    BANK_PAYMENT_VOID: 'bank-payment-void',
    BANK_SYNC_LINE_DELETE: 'bank-sync-line-delete',
    BANK_SYNC_LINE_DROP: 'bank-sync-line-drop',
    BANK_SYNC_LOAD: 'bank-sync-load',
    BANK_SYNC_MATCH_REMOVE: 'bank-sync-match-remove',
    BANK_SYNC_MATCH_SAVE: 'bank-sync-match-save',
    BANK_SYNC_MATCH_SELECT_DIFFERENT_TYPE: 'bank-sync-match-select-different-type',
    BANK_SYNC_TRANSACTION_CREATE: 'bank-sync-transaction-create',
    BILL_DELETE: 'bill-delete',
    BILL_DISCARD: 'bill-discard',
    BILL_DRAFT_SAVE: 'bill-draft-save',
    BILL_DRAFT_SAVE_NO_REDIRECT: 'bill-draft-save-no-redirect',
    BILL_SAVE: 'bill-save',
    BILL_SAVE_NO_REDIRECT: 'bill-save-no-redirect',
    BILL_VOID: 'bill-void',
    BILLS_BULK_APPROVE: 'bills-bulk-approve',
    BILLS_BULK_APPROVE_LAST: 'bills-bulk-approve-last',
    BILLS_DELETE: 'bills-delete',
    CARD_ADDED: 'card-added',
    CLIPBOARD: 'clipboard',
    CONTACT_ARCHIVE: 'contact-archive',
    CONTACT_DELETE: 'contact-delete',
    CONTACT_PREPAYMENT_APPROVE: 'contact-prepayment-approve',
    CONTACT_SAVE: 'contact-save',
    CONVERSION_BALANCE_UPDATE: 'conversion-balance-update',
    COUPON_REDEEM: 'coupon-redeem',
    DAYBOOK_ARCHIVE: 'daybook-archive',
    DAYBOOK_DELETE: 'daybook-delete',
    DAYBOOK_IMPORT_UPLOAD_FILE: 'daybook-import-upload-file',
    DAYBOOK_SAVE: 'daybook-save',
    DAYBOOK_TRANSACTIONS_APPROVE_RECORD_INVALID: 'daybook-transactions-approve-record-invalid',
    DAYBOOK_TRANSACTIONS_LOAD: 'daybook-transactions-load',
    FEC_REPORT_SENT: 'fec-report-sent',
    FILE_SIZE_LIMIT: 'file-size-limit',
    FILE_UPLOAD_ENQUEUE: 'file-upload-enqueue',
    FINANCING_ESTIMATE_ALREADY_APPROVED: 'financing-estimate-already-approved',
    INVOICE_DISCARD: 'invoice-discard',
    INVOICE_DRAFT_DELETE: 'invoice-draft-delete',
    INVOICE_DRAFT_SAVE: 'invoice-draft-save',
    INVOICE_DUPLICATE: 'invoice-duplicate',
    INVOICE_EAN_SEND: 'invoice-ean-send',
    INVOICE_EMAIL_SEND: 'invoice-email-send',
    INVOICE_ERROR: 'invoice-error',
    INVOICE_LATE_FEE_VOID: 'invoice-late-fee-void',
    INVOICE_LATE_FEE_VOID_ABORT: 'invoice-late-fee-void-abort',
    INVOICE_REMINDER_EDITOR_DOWNLOAD_STARTED: 'invoice-reminder-editor-download-started',
    INVOICE_RESTORE: 'invoice-restore',
    INVOICE_SAVE: 'invoice-save',
    INVOICE_VOID: 'invoice-void',
    JOURNAL_DELETE: 'journal-delete',
    LOGIN_EMAIL_SENT: 'login-email-sent',
    MOBILEPAY_ACTIVE_CLICK: 'mobilepay-activate-click',
    MOBILEPAY_CREATE: 'mobilepay-create',
    NOTIFICATION_SETTINGS_PATCH: 'notification-settings-patch',
    OGRANIZATION_EMAIL_SEND: 'organization-email-send',
    ORGANIZATION_ADDON_DOWNGRADE_ABORT: 'organization-addon-downgrade-about',
    ORGANIZATION_BILLING_CONSOLIDATE: 'organization-billing-consolidate',
    ORGANIZATION_FISCAL_SETTINGS_SAVE: 'organization-fiscal-settings-save',
    ORGANIZATION_ICON_UPLOADED: 'organization-icon-uploaded',
    ORGANIZATION_INVITATION_ACCEPT: 'organization-invitation-accept',
    ORGANIZATION_INVITATION_DELETE: 'organization-invitation-delete',
    ORGANIZATION_INVITATION_SIGNUP: 'organization-invitation-signup',
    ORGANIZATION_LOCKED_UPGRADE: 'organization-locked-upgrade',
    ORGANIZATION_LOGO_UPLOADED: 'organization-logo-uploaded',
    ORGANIZATION_OWNER_DELETE: 'organization-owner-delete',
    ORGANIZATION_OWNER_TRANSFER: 'organization-owner-transfer',
    ORGANIZATION_ROLE_FLIP: 'organization-role-flip',
    ORGANIZATION_SETTINGS_UPDATED: 'organization-settings-updated',
    ORGANIZATION_SUBSCRIPTION_CANCELLED: 'organization-subscription-cancelled',
    ORGANIZATION_TASK_SAVE: 'organization-task-save',
    ORGANIZATION_USER_REMOVE: 'organization-user-remove',
    OWNER_REFERENCE_UPDATE: 'owner-reference-update',
    PASSWORD_CHANGE: 'password-change',
    PAYMENT_CANCELLED: 'payment-cancelled',
    PAYMENT_CURRENCY: 'payment-currency',
    PAYMENT_DELETE: 'payment-delete',
    PAYMENT_METHOD_REMOVE: 'payment-method-remove',
    PLAN_CHANGE: 'plan-change',
    PLAN_DOWNGRADE: 'plan-downgrade',
    PLAN_PAYMENT_CONFIRM: 'plan-payment-confirm',
    PLAN_PERIOD_CHANGE: 'plan-period-change',
    PLAN_UPGRADE: 'plan-upgrade',
    PRIVACY_SETTINGS_UPDATE: 'privacy-settings-update',
    PRODUCT_DELETE: 'product-delete',
    QUOTE_CLOSE: 'quote-close',
    QUOTE_DELETE: 'quote-delete',
    QUOTE_PDF_GENERATING: 'quote-pdf-generating',
    QUOTE_VALIDATE: 'quote-validate',
    RECURRING_INVOICE_DELETE: 'recurring-invoice-delete',
    REJECT_BANK_MATCH_SUGGESTION: 'reject-bank-match-suggestion',
    REQUEST_LOAN: 'request-loan',
    SALES_TAX_ACCOUNT_DELETE: 'sales-tax-account-delete',
    SALES_TAX_META_DELETE: 'sales-tax-meta-delete',
    SALES_TAX_META_EDIT: 'sales-tax-meta-edit',
    SALES_TAX_PERIOD_CHANGE: 'sales-tax-period-change',
    SALES_TAX_SEND: 'sales-tax-send',
    SALES_TAX_SETTLE: 'sales-tax-settle',
    SALES_TAX_UNSETTLE: 'sales-tax-unsettle',
    SALES_TAX_VOID_PAYMENT: 'sales-tax-void_payment',
    STILL_UPLOADING: 'still-uploading',
    STILL_UPLOADING_ATTACHMENTS: 'still-uploading-attachments',
    SUBSCRIPTION_UPDATE: 'subscription-update',
    TASK_ITEM_APPROVE: 'task-item-approve',
    TASK_ITEM_COMPLETE: 'task-item-complete',
    TAX_CORRECTION_CREATE: 'tax-correction-create',
    TAX_CREATE: 'tax-create',
    TAX_RATE_DELETE: 'tax-rate-delete',
    TAX_RULESET_DELETE: 'tax-ruleset-delete',
    TEMPLATE_ATTACHMENT_UPLOAD: 'template-attachment-upload',
    TEMPLATE_CREATE: 'template-create',
    TEMPLATE_DELETE: 'template-delete',
    TEMPLATE_DUPLICATE: 'template-duplicate',
    TEMPLATE_SAVE: 'template-save',
    TEMPLATE_SET_DEFAULT: 'template-set-default',
    TRANSACTION_APPROVED: 'transaction-approved',
    UMBRELLA_CONSOLIDATED_BILLING_ERROR: 'umbrella-consolidated-billing-error',
    UMBRELLA_HELPER_REQUEST: 'umbrella-helper-request',
    UMBRELLA_INVITATION_ACCEPT: 'umbrella-invitation-accept',
    UMBRELLA_INVITATION_DELETE: 'umbrella-invitation-delete',
    UMBRELLA_INVITATION_SIGNUP: 'umbrella-invitation-signup',
    UMBRELLA_ITEM_DELETE: 'umbrella-item-delete',
    UMBRELLA_ORGANIZATION_REMOVE: 'umbrella-organization-remove',
    UMBRELLA_ROLE_DELETE: 'umbrella-role-delete',
    UMBRELLA_SCHEDULE_DELETE: 'umbrella-schedule-delete',
    UMBRELLA_SCHEDULE_DUPLICATE: 'umbrella-schedule-duplicate',
    UMBRELLA_USER_REMOVE: 'umbrella-user-remove',
    USER_CHECK_EMAIL: 'user-check-email',
    USER_SETTINGS_UPDATE: 'user-settings-update',
    VAT_SETTLEMENTS: 'vat-settlements'
}

module.exports = NOTIFICATION_KEYS
