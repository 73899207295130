var t = require('i18n').t
var snapshotInvoiceMock = require('../utils/snapshot-invoice-mock')
var NOTIFICATION_KEYS = require('../notificationKeys')

module.exports = Em.Controller.extend({
    needs: ['user'],

    organization: Em.computed.oneWay('controllers.user.activeOrganization'),

    isBillyProviderDk: Em.computed.equal('organization.billyProviderId', 'dk'),

    templates: Ember.inject.service(),

    model: null,

    branch: null,

    locales: null,

    activeLocale: null,

    sections: null,

    activeSection: null,

    previewHTML: null,

    isClean: true,

    isSaveDisabled: false,

    standardAttachmentFile: null,

    templateEditorEnabledLocales: ['da_DK', 'en_US'],

    initSections: function() {
        var sections = [
            Em.Object.create({
                name: t('templates.section_appearance')
            }),
            Em.Object.create({
                name: t('templates.create_languages')
            })
        ]

        this.set('sections', sections)
        this.set('activeSection', sections[0])
    }.on('init'),

    uploadHeaders: {
        'X-Image-Max-Width': 500,
        'X-Image-Max-Height': 500
    },

    loadStandardAttachment: function() {
        var self = this
        var attachmentFileId = this.get('branch.variables.default.standardAttachment')

        if (attachmentFileId) {
            this.get('api').request('GET', '/v2/files/' + attachmentFileId)
                .then(function(payload) {
                    if (payload.file) {
                        self.set('standardAttachmentFile', payload.file)
                        self.set('standardAttachmentFileThumbnail', payload.file.downloadUrl)
                    }
                })
        }
    }.observes('branch.variables.default.standardAttachment'),

    standardAttachmentUploadText: function() {
        return this.get('standardAttachmentFile') ? t('change') : t('v2_file_upload_field.upload_tool_text')
    }.property('standardAttachmentFile'),

    activeSectionDidChange: function() {
        var activeSection = this.get('activeSection')
        this.get('sections').forEach(function(item) {
            item.set('active', item === activeSection)
        })
    }.observes('activeSection'),

    addObserver: function(observable, keys) {
        var self = this
        keys = keys || Em.keys(observable)
        keys.forEach(function(key) {
            Em.addObserver(observable, key, function() {
                self.setPreviewHTML()
                self.set('isClean', false)
            })
        })
    },

    removeObserver: function(observable, keys) {
        var self = this
        keys = keys || Em.keys(observable)
        keys.forEach(function(key) {
            Em.removeObserver(observable, key, function() {
                self.setPreviewHTML()
                self.set('isClean', false)
            })
        })
    },

    handleObservers: function(action) {
        if (action === 'add') {
            this.addObserver(this.get('branch.variables.default'))
            if (this.get('branch.variables.da_DK')) {
                this.addObserver(this.get('branch.variables.da_DK'))
            }
            if (this.get('branch.variables.en_US')) {
                this.addObserver(this.get('branch.variables.en_US'))
            }
            this.addObserver(this.get('branch'), ['fallbackLocale', 'name'])
        } else if (action === 'remove') {
            this.removeObserver(this.get('branch.variables.default'))
            if (this.get('branch.variables.da_DK')) {
                this.removeObserver(this.get('branch.variables.da_DK'))
            }
            if (this.get('branch.variables.en_US')) {
                this.removeObserver(this.get('branch.variables.en_US'))
            }
            this.removeObserver(this.get('branch'), ['fallbackLocale', 'name'])
        }
    },

    isAppearanceSectionActive: function() {
        return this.get('activeSection.name') === t('templates.section_appearance')
    }.property('activeSection'),

    isLanguageSectionActive: function() {
        return this.get('activeSection.name') === t('templates.create_languages')
    }.property('activeSection'),

    setPreviewHTML: function() {
        var layout = this.get('documentLayouts').findBy('id', this.get('activeLocale').locale).data
        this.set('previewHTML', snapshotInvoiceMock(this.get('branch'), layout, this.get('organization'), null, this.get('activeLocale').locale))
    },

    fork: function(model) {
        this.set('model', model)
        var branch = Em.copy(model, true)

        this.set('branch', branch)

        // Collect locales
        var vs = branch.variables
        if (!vs.default) {
            vs.default = {}
        }
        var locales = []
        this.set('locales', locales)
        for (var locale in vs) {
            if (locale === 'default' || vs[locale] === null) {
                continue
            }
            this.addLocale(locale, vs[locale])
        }
        locales.sortBy('name')
        // set the organization locale as the active locale if it exists
        this.set('activeLocale', locales.findBy('locale', this.get('organization.locale.id')) || locales[0])
        this.set('isClean', true)
        this.setPreviewHTML()
        this.handleObservers('add')
    },

    isDefault: function() {
        return this.get('organization.defaultTemplateId') === this.get('model.id')
    }.property('organization.defaultTemplateId', 'model.id'),

    templatesUrl: function() {
        return '/organizations/' + this.get('organization.id') + '/templates'
    }.property('organization.id'),

    templateUrl: function() {
        return this.get('templatesUrl') + '/' + this.get('model.id')
    }.property('templatesUrl', 'model.id'),

    addLocale: function(locale, variables) {
        var item = Em.Object.create({
            locale: locale,
            name: Billy.Locale.find(locale).get('name'),
            variables: variables
        })
        this.get('branch').variables[locale] = variables
        this.get('locales').pushObject(item)
        return item
    },

    activeLocaleDidChange: function() {
        var activeLocale = this.get('activeLocale')
        this.get('locales').forEach(function(item) {
            item.set('active', item === activeLocale)
        })
        this.setPreviewHTML()
    }.observes('activeLocale'),

    // Maps a Billy.Locale to the fork.fallbackLocale which is just an ID
    fallbackLocale: function(key, value) {
        if (arguments.length > 1) {
            this.set('branch.fallbackLocale', value && value.get('id'))
        } else {
            var id = this.get('branch.fallbackLocale')
            return id && Billy.Locale.find(id)
        }
    }.property('branch.fallbackLocale'),

    addLocaleVisible: false,

    newLocale: null,

    newLocaleError: null,

    hideNewLocale: function() {
        this.set('newLocaleVisible', false)
            .set('newLocale', null)
            .set('newLocaleError', null)
    },

    hasMultipleLocales: function() {
        return this.get('locales.length') > 1
    }.property('locales.length'),

    logoAlignOptions: function() {
        return ['left', 'center', 'right'].map(function(v) {
            return Em.O({
                value: v,
                name: t('templates.logo_alignment_options.' + v)
            })
        })
    }.property(),

    fontWeightOptions: function() {
        return ['300', '400', '600'].map(function(v) {
            return Em.O({
                value: v,
                name: t('templates.font_weight_options.' + v)
            })
        })
    }.property(),

    fontFamilyOptions: function() {
        var options = [
            'Arial',
            'Courier New',
            'Helvetica',
            'Aeonik',
            'Times New Roman'
        ]
        return options.map(function(v) {
            return Em.O({
                value: v,
                name: v
            })
        })
    }.property(),

    invoiceLayoutOptions: function() {
        return ['default'].map(function(v) {
            return Em.O({
                value: v,
                name: v
            })
        })
    }.property(),

    quoteLayoutOptions: Em.computed.oneWay('invoiceLayoutOptions'),

    actions: {
        showNewLocale: function() {
            this.set('newLocaleVisible', true)
        },

        hideNewLocale: function() {
            this.hideNewLocale()
        },

        uploadInProgress: function(bool) {
            this.set('isSaveDisabled', bool)
        },

        didStartUploadingTemplateAttachment: function() {
            this.set('isSaveDisabled', true)
        },

        didUploadTemplateAttachment: function(context) {
            var self = this

            this.set('standardAttachmentFile', context.file)
            this.set('standardAttachmentFileThumbnail', context.file.get('downloadUrl'))
            this.set('branch.variables.default.standardAttachment', context.file.get('id'))

            // Hack - Also upload the file to v2-files to be able to use it on quotes.
            var uploadField = this.container.lookup('component:v2-file-upload-field')
            uploadField.upload(context.browserFile)
                .then(function(url) {
                    self.set('branch.variables.default.standardAttachmentUrl', url)
                })
                .catch(function() {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_ATTACHMENT_UPLOAD, t('attachments_changeset.upload_failed'))
                    self.send('deleteAttachment')
                })
                .finally(function() {
                    self.set('isSaveDisabled', false)
                })
        },

        didUploadLogo: function(context) {
            this.set('branch.variables.default.logoUrl', context.file.get('downloadUrl'))
        },

        removeLogo: function() {
            this.set('branch.variables.default.logoUrl', null)
        },

        didUploadBackgroundImage: function(context) {
            this.set('branch.variables.default.backgroundImageUrl', context.file.get('downloadUrl'))
        },

        removeBackground: function() {
            this.set('branch.variables.default.backgroundImageUrl', null)
        },

        deleteAttachment: function() {
            this.set('standardAttachmentFile', null)
            this.set('standardAttachmentFileThumbnail', null)
            this.set('branch.variables.default.standardAttachment', null)
            this.set('branch.variables.default.standardAttachmentUrl', null)
        },

        didSelectNewLocale: function(newLocale) {
            var locale = newLocale.get('id')
            if (!locale) {
                this.set('newLocaleError', t('required_field'))
                return
            }
            var existing = this.get('locales').findBy('locale', locale)
            if (existing) {
                this.set('newLocaleError', t('templates.language_already_in_use'))
                return
            }
            // set at least one key so that the locale is not deleted
            var l = this.addLocale(locale, {
                invoiceEmailSubject: ''
            })
            this.set('activeLocale', l)
            this.hideNewLocale()
            this.set('isClean', false)
        },

        activateLocale: function(locale) {
            this.set('activeLocale', locale)
        },

        activateSection: function(section) {
            this.set('activeSection', section)
        },

        removeLocale: function(item) {
            var locales = this.get('locales')
            var index = locales.indexOf(item)
            locales.removeObject(item)
            this.get('branch').variables[item.locale] = null
            this.set('activeLocale', locales[Math.min(index, locales.length - 1)])
            this.set('isClean', false)
        },

        save: function() {
            var self = this
            var branch = this.get('branch')
            this.get('api').patch(this.get('templateUrl'), {
                mask: true,
                payload: {
                    data: branch
                }
            })
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_SAVE, t('templates.changes_saved'))
                    self.fork(branch)
                    self.get('templates').load()
                }, function(e) {
                    self.container.lookup('util:notification').warn(NOTIFICATION_KEYS.TEMPLATE_SAVE, e.message)
                })
        },

        setDefault: function() {
            var self = this
            this.get('organization')
                .set('defaultTemplateId', this.get('model.id'))
                .save()
                .then(function() {
                    self.container.lookup('util:notification').success(NOTIFICATION_KEYS.TEMPLATE_SET_DEFAULT, t('templates.default_changed'))
                })
        },

        preview: function() {
            var previewWin = this.container.lookup('component:template-preview-window')
            previewWin.html = this.get('previewHTML')
            previewWin.show()
        },

        navigate: function(route) {
            this.transitionToRoute(route)
        }
    }
})
