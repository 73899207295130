require('ember')
require('ember-data')
require('modernizr')
window.jQuery = require('jquery')
window.moment = require('moment')
require('spectrum')
require('d3')
require('linkify')
require('linkify-string')
require('event-source-polyfill')

// Ember flags
ENV.LOG_VERSION = false
ENV.FEATURES = {
    'query-params': true
}

// Require necessary global modules
require('billy-data')
var reactWebComponents = window.billyReact

// Framework additions
require('./framework-additions/billy-data')
require('./framework-additions/ember')
require('./framework-additions/liquid-js')
require('./framework-additions/moment')
require('./framework-additions/numeral')

// I18n
var i18n = require('i18n')
var t = i18n.t
var moment = require('momentjs')
var momentLanguages = require('moment-languages')
var numeral = require('numeral')
var numeralLanguages = require('numeral-languages')
var billyApi = require('./utils/billy-api')

// Instantiate all momentLanguages
for (var ml in momentLanguages) {
    if (Object.prototype.hasOwnProperty.call(momentLanguages, ml)) {
        moment.lang(ml, require('moment-languages')[ml])
    }
}

// Instantiate all numeralLanguages (except 'en')
for (var nl in numeralLanguages) {
    if (Object.prototype.hasOwnProperty.call(numeralLanguages, nl) && nl !== 'en') {
        numeral.language(nl, numeralLanguages[nl])
    }
}

// Listen for changes on locale
i18n.addChangeListener(function(locale, shortLocale) {
    moment.lang(shortLocale, momentLanguages[shortLocale])
    moment.lang(shortLocale)

    reactWebComponents && reactWebComponents.setLanguage({ lang: shortLocale })
    if (shortLocale !== 'en') {
        numeral.language(shortLocale, numeralLanguages[shortLocale])
    }
    numeral.language(shortLocale)

    billyApi.options.language = locale
})

var locales = {
    en_US: require('./locales/en_US'),
    da_DK: require('./locales/da_DK'),
    fr_FR: require('./locales/fr_FR'),
    de_DE: require('./locales/de_DE'),
    nl_NL: require('./locales/nl_NL')
}

i18n.init(locales)

// Setup Application
var Application = require('./application')
var app = Application.create({})
window.Billy = app

BD.registerTypeNamespace(window.Billy)
window.Billy.constants = {
    lineHeight: 24,
    smallLineHeight: 18,
    standardPadding: 30,
    tightStandardPadding: 10,
    toolSize: 24,
    cellPadding: 5,
    niceMaxWidth: 800
}

// Dirty utils
window._ = require('lodash')
require('./utils/images')
require('./utils/money')
require('./utils/accounting/discount')
require('./utils/accounting/side-amount')
require('./utils/file-upload-request')
require('./utils/background-task')
require('./utils/functions')
require('./ui/button')

// Good requires
require('./models')
require('./helpers')

// Popovers should be constrained by .application element
require('ember-popover').reopen({
    within: '.application'
})

// RSVP error handling
Em.onerror = function(e) {
    if (e && e.name !== 'BillyApiError' && e.name !== 'DataOperationError' && !(e instanceof DS.InvalidError)) {
        Ember.Logger.assert(false, e)
    }
}

// Set ember-file-uploader's URL
var fileUploader = require('ember-file-uploader')
fileUploader.reopen({
    url: ENV.apiUrl + '/files'
})
fileUploader.setHeader('Accept-Language', i18n.locale())
fileUploader.setHeader('X-Access-Token', billyApi.storageAdapter.getValue('accessToken'))
billyApi.on('authorize', function(accessToken) {
    fileUploader.setHeader('X-Access-Token', accessToken)
})

// Add fiscalyear to period-selector
require('ember-period-selector').add('dates', 'fiscalYear', t('fiscalyear'), require('./utils/period-selector/fiscal-year-period'), require('./utils/period-selector/fiscal-year-view'))

// Make sure that all layers (windows, popovers etc.) are at z-index 1000 at a minimum. This is so they are above fixed elements such as {{floating-ct}}
require('highest-z-index').setMin(1000)
