module.exports = require('./abstract-annual-report').extend({
    annualReport: Em.inject.service(),
    annualReportCredits: Em.inject.service(),

    queryParams: {
        hasBoughtPackage: {
            replace: true
        }
    },

    model: function() {
        return Em.RSVP.all([
            this.modelFor('annual_report'),
            this.get('annualReport').calculatePrice(),
            this.get('annualReport').calculateAccountingPackagePrice(),
            this.get('annualReport').getAnnualReportSubscription(),
            this.get('annualReportCredits').loadUnusedCredits()
        ])
    },

    afterModel: function(values) {
        var model = values[0]

        var currentStep = this.get('annualReport').getCurrentStep(model.commitment)
        if (currentStep !== 'start') {
            var activeStep = this.get('annualReport.activeStep')
            if (!this.get('annualReport').getStepOrder().includes(activeStep)) {
                this.replaceWith('annual_report.' + currentStep, model)
            }
        }
    },

    setupController: function(controller, values, transition) {
        var commitment = values[0]
        var basePrice = values[1].annual
        var basePriceOneoff = values[1].oneoff
        var accountingPackagePrice = values[2]
        var arSubscription = values[3]
        var unusedCredits = this.get('annualReportCredits.hasUnusedCredits')

        var hasARSubscription = !!(arSubscription && arSubscription.ID)

        this._super(controller, commitment, transition)
        controller.set('hasBoughtPackage', (transition.queryParams.hasBoughtPackage || transition.queryParams.package_bought))
        controller.set('basePrice', basePrice)
        controller.set('basePriceOneoff', basePriceOneoff)
        controller.set('accountingPackagePrice', accountingPackagePrice)

        controller.set('hasARSubscription', hasARSubscription)
        controller.set('showSubscriptionInfo', commitment.commitment.year >= 2022 && hasARSubscription)
        controller.set('subscriptionRenewalDate', this.get('annualReport').getSubscriptionRenewalDate(arSubscription))
        controller.set('hasARSubscriptionAndNotRenewed', !unusedCredits)
        controller.set('hasARSubscriptionAndRenewed', unusedCredits)
    }
})
