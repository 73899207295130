if (Ember.inject) {
    // eslint-disable-next-line no-console
    console.warn('It seems that Ember.inject now exists. The code in app/framework-additions/ember/inject.js should be removed.')
} else {
    Ember.inject = {
        service: function() {
            return Ember.computed(function(key) {
                if (arguments.length > 1) {
                    throw new Error('Injected services properties cannot be set.')
                }
                return this.get('container').lookup('service:' + key)
            })
        }
    }
}
