module.exports = {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'mln',
        billion: 'mrd',
        trillion: 'bln'
    },
    ordinal: function (number) {
        return 'e';
    },
    currency: {
        symbol: '€'
    }
};