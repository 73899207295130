module.exports = require('ember-super-field').types.Model.extend({
    inputValuePath: 'name',

    iconPath: 'iconUrl',

    modelClass: Billy.Locale,

    sortProperty: 'name',

    allowedValues: null,

    // Small workaround to allow to locally filter available locales, i.e.
    // when selecting a locale for a customer (since we are not supporting
    // all languages in the invoice editor yet)
    parser: function(data) {
        var allowedValues = this.get('field.allowedValues')

        if (allowedValues) {
            var contentFiltered = {}
            var index = 0

            data.forEach(function(item) {
                if (allowedValues.indexOf(item.get('id')) !== -1) {
                    contentFiltered[index] = item
                    index++
                }
            })

            data.set('_content', contentFiltered)
            data.set('length', _.size(contentFiltered))
        }

        return data
    },

    optionViewClass: require('ember-super-field').OptionView.extend({
        template: Ember.Handlebars.compile(
            '<div class="image"><img {{bind-attr src="iconUrl"}}/></div>' +
                '<div class="image-content">' +
                '<div class="name">{{name}}</div>' +
            '</div>'
        )
    })
})
