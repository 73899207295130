module.exports = {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'Tsd',
        million: 'Mio',
        billion: 'Mrd',
        trillion: 'Bio'
    },
    ordinal: function (number) {
        return '.';
    },
    currency: {
        symbol: '€'
    }
};