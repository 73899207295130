var _ = require('lodash')
var functionProxy = require('function-proxy')

module.exports = {
    draggable: function(el, options) {
        return new Draggable(el, options)
    },

    droppable: function(el, options) {
        return new Droppable(el, options)
    }
}

var droppables = []
var overDroppable
var isAboveCenter
var removeTouchMoveHandler = null
var removeDragStartHandler = null
var removeTouchStartHandler = null

function addPassiveHandler(element, event, handler) {
    element.addEventListener(event, handler, { passive: false })

    return function() {
        element.removeEventListener(event, handler, { passive: false })
    }
}

function setOverDroppable(newOverDroppable, newIsAboveCenter) {
    if (newOverDroppable !== overDroppable || newIsAboveCenter !== isAboveCenter) {
        if (overDroppable) {
            overDroppable._out(isAboveCenter)
        }

        overDroppable = newOverDroppable
        isAboveCenter = newIsAboveCenter

        if (overDroppable) {
            overDroppable._over(isAboveCenter)
        }
    }
}

var Draggable = function(el, options) {
    this._el = el
    _.extend(this, options)
    this.init()
}

Draggable.prototype = {
    distance: 1,

    touchDelay: 200,

    helper: null,

    appendTo: null,

    zIndex: 1000,

    cancel: null,

    start: noop,

    stop: noop,

    isTouch: false,

    init: function() {
        removeTouchStartHandler = addPassiveHandler(this._el[0], 'touchstart', functionProxy(this._onTouchStart, this))

        this._el.css({
            '-webkit-touch-callout': 'none',
            '-webkit-user-select': 'none'
        })
        this._el.on('mousedown', functionProxy(this._onMouseDown, this))

        removeDragStartHandler = addPassiveHandler(this._el[0], 'selectstart dragstart', preventEvent)
    },

    destroy: function() {
        if (removeTouchStartHandler) {
            removeTouchStartHandler()
            removeTouchStartHandler = null
        }

        this._el.off('mousedown', functionProxy(this._onMouseDown, this))

        if (removeDragStartHandler) {
            removeDragStartHandler()
            removeDragStartHandler = null
        }

        if (this._isActive) {
            this._destroyHelper()
        }
    },

    _isWithinCancel: function(e) {
        var cancel = this._el.find(this.cancel)
        return (cancel.length > 0 && (cancel.is(e.target) || $.contains(cancel[0], e.target)))
    },

    _storeHandleOffset: function(e) {
        var elOffset = this._el.offset()
        var pos = this.getPos(e)
        this._handleOffset = [pos[0] - elOffset.left, pos[1] - elOffset.top]
    },

    _onTouchStart: function(e) {
        var self = this

        if (this._isWithinCancel(e)) {
            return
        }

        this.isTouch = true
        this._storeHandleOffset(e)

        $(window).on('touchend', functionProxy(this._onTouchEnd, this))

        if (removeTouchMoveHandler) {
            removeTouchMoveHandler()
        }

        removeTouchMoveHandler = addPassiveHandler(window, 'touchmove', functionProxy(this._onTouchMove, this))

        $(document.body).css({
            '-webkit-touch-callout': 'none',
            '-webkit-user-select': 'none'
        })

        var pos = this.getPos(e)

        this._touchTimeout = setTimeout(function() {
            self._startDrag(pos)
        }, this.touchDelay)
    },

    _cancelTouch: function() {
        clearTimeout(this._touchTimeout)

        $(window).off('touchend', functionProxy(this._onTouchEnd, this))

        if (removeTouchMoveHandler) {
            removeTouchMoveHandler()
            removeTouchMoveHandler = null
        }

        $(document.body).css({
            '-webkit-touch-callout': '',
            '-webkit-user-select': ''
        })
    },

    _onTouchEnd: function(e) {
        this._cancelTouch()
        if (this._isActive) {
            this._endDrag(e)
        }
    },

    _onTouchMove: function(e) {
        if (this._isActive) {
            e.preventDefault()
            this._drag(e)
            return false
        } else {
            this._cancelTouch()
        }
    },

    _onMouseDown: function(e) {
        if (this._isWithinCancel(e)) {
            return
        }

        this.isTouch = false

        this._storeHandleOffset(e)

        this._mouseDownPos = this.getPos(e)

        $(window).on('mouseup', functionProxy(this._onMouseUp, this))
        $(window).on('mousemove', functionProxy(this._onMouseMove, this))

        if (removeDragStartHandler) {
            removeDragStartHandler()
        }

        removeDragStartHandler = addPassiveHandler(window, 'selectstart dragstart', preventEvent)
    },

    _onMouseUp: function(e) {
        $(window).off('mouseup', functionProxy(this._onMouseUp, this))
        $(window).off('mousemove', functionProxy(this._onMouseMove, this))

        if (removeDragStartHandler) {
            removeDragStartHandler()
            removeDragStartHandler = null
        }

        delete this._mouseDownPos

        this._endDrag(e)
    },

    _onMouseMove: function(e) {
        if (!this._isActive) {
            var distanceMoved = Math.sqrt(Math.pow(this._mouseDownPos[0] - e.clientX, 2) + Math.pow(this._mouseDownPos[1] - e.clientY, 2))
            if (distanceMoved > this.distance) {
                this._startDrag(this.getPos(e))
            }
        } else {
            this._drag(e)
        }
    },

    _startDrag: function(pos) {
        var self = this

        this._isActive = true

        this._createHelper()
        this._updateHelperPosition(pos)

        $(document.body).css('cursor', 'move')

        droppables.forEach(function(droppable) {
            if (droppable.accept(self)) {
                droppable._startDrag()
            }
        })

        this.start(this)
    },

    _endDrag: function(e) {
        delete this._handleOffset
        this._isActive = false

        $(document.body).css('cursor', 'default')

        if (overDroppable) {
            overDroppable.drop(e, this, isAboveCenter)
            this._destroyHelper()
        } else {
            this._revertHelper()
        }

        setOverDroppable(null, null)

        droppables.forEach(function(droppable) {
            droppable._endDrag()
        })

        this.stop(e, this)
    },

    _drag: function(e) {
        this._updateHelperPosition(this.getPos(e))

        var newOverdroppable = null
        var newIsAboveCenter = null

        if (droppables.length > 0) {
            var possibleOverDroppables = []
            var helperEl = this._helperEl
            var helperOffset = helperEl.offset()
            var x1 = helperOffset.left
            var y1 = helperOffset.top
            var x2 = x1 + helperEl.outerWidth()
            var y2 = y1 + helperEl.outerHeight()
            var ym = (y1 + y2) / 2

            droppables.forEach(function(droppable) {
                if (!droppable._isActive) {
                    return
                }

                var area = droppable._calculateIntersectionArea(x1, y1, x2, y2)
                var isAboveCenter = droppable._isAboveCenter(ym)
                if (area > 0) {
                    possibleOverDroppables.push({
                        droppable: droppable,
                        area: area,
                        isAboveCenter: isAboveCenter
                    })
                }
            })

            if (possibleOverDroppables.length > 0) {
                possibleOverDroppables.sort(function(a, b) {
                    return b.area - a.area
                })

                newOverdroppable = possibleOverDroppables[0].droppable
                newIsAboveCenter = possibleOverDroppables[0].isAboveCenter
            }
        }

        setOverDroppable(newOverdroppable, newIsAboveCenter)
    },

    _createHelper: function() {
        this._helperEl = this.helper()
        this._helperEl.css({
            'z-index': this.zIndex
        })
        this._helperEl.addClass('ui-state-default')
        this._helperEl.appendTo(this.appendTo || document.body)
    },

    _revertHelper: function() {
        if (!this._helperEl) {
            return
        }
        var self = this
        var helperEl = this._helperEl
        var helperOffset = helperEl.offset()
        var elOffset = this._el.offset()
        var left = elOffset.left - helperOffset.left
        var top = elOffset.top - helperOffset.to
        helperEl.animate({
            left: (left > 0 ? '+=' : '-=') + Math.abs(left),
            top: (top > 0 ? '+=' : '-=') + Math.abs(top)
        }, 200, function() {
            self._destroyHelper()
        })
    },

    _destroyHelper: function() {
        this._helperEl.remove()
    },

    _updateHelperPosition: function(pos) {
        var left = pos[0] - this._handleOffset[0]
        var top = pos[1] - this._handleOffset[1]
        var helperEl = this._helperEl
        var helperWidth = helperEl.outerWidth()
        var helperHeight = helperEl.outerHeight()
        /*
            SILENCED ERROR
            The problem is that after removing some line (for example in Invoices), there is some wrong
            reinitialization of the rest lines and 'helperEl' doesn't have a proper parent element, so it can't get the right coordinates.
            In most cases 'appendTo' element is the same as the 'parent' element, but assigning that doesn't resolve the problem.
            It only silences it. This code has 10 years old and it's not something new.
            Decided to go with that solution also because this is the view we're about to rewrite to React.
        */
        var parentEl = helperEl.parent().length ? helperEl.parent() : this.appendTo
        var parentOffset = parentEl.offset()

        if (!parentOffset) {
            return
        }

        var parentLeft = parentOffset.left
        var parentTop = parentOffset.top
        var parentWidth = parentEl.outerWidth()
        var parentHeight = parentEl.outerHeight()

        left = Math.min(Math.max(left, parentLeft), parentLeft + parentWidth - helperWidth)
        top = Math.min(Math.max(top, parentTop), parentTop + parentHeight - helperHeight)

        if (this.isTouch) {
            left += 10
            top += 10
        }

        this._helperEl.offset({
            left: left,
            top: top
        })
    },

    getHelperEl: function() {
        return this._helperEl
    },

    getPos: function(e) {
        if (this.isTouch) {
            var targetTouches

            if (e.originalEvent) {
                targetTouches = e.originalEvent.targetTouches
            } else {
                targetTouches = e.targetTouches
            }

            return [targetTouches[0].pageX, targetTouches[0].pageY]
        } else {
            return [e.clientX, e.clientY]
        }
    }
}

var Droppable = function(el, options) {
    this._el = el
    _.extend(this, options)
    this.init()
}

Droppable.prototype = {
    activeClass: 'droppable-active',

    hoverClass: 'droppable-hover',

    hoverAboveClass: 'droppable-hover-above',

    hoverBelowClass: 'droppable-hover-below',

    accept: function() {
        return true
    },

    drop: noop,

    init: function() {
        droppables.push(this)
    },

    destroy: function() {
        droppables.removeObject(this)
    },

    _startDrag: function() {
        this._isActive = true
        this._el.addClass(this.activeClass)
    },

    _endDrag: function() {
        this._isActive = false
        this._el.removeClass(this.activeClass)
    },

    _over: function(isAboveCenter) {
        this._el.addClass(this.hoverClass)
        this._el.addClass(isAboveCenter ? this.hoverAboveClass : this.hoverBelowClass)
    },

    _out: function(isAboveCenter) {
        this._el.removeClass(this.hoverClass)
        this._el.removeClass(isAboveCenter ? this.hoverAboveClass : this.hoverBelowClass)
    },

    _calculateIntersectionArea: function(x1, y1, x2, y2) {
        var el = this._el
        var elOffset = el.offset()
        var left = Math.max(x1, elOffset.left)
        var top = Math.max(y1, elOffset.top)
        var right = Math.min(x2, elOffset.left + el.outerWidth())
        var bottom = Math.min(y2, elOffset.top + el.outerHeight())
        if (left >= right || top >= bottom) {
            return 0
        }
        return (right - left) * (bottom - top)
    },

    _isAboveCenter: function(ym1) {
        var el = this._el
        var elOffset = el.offset()
        var ym2 = elOffset.top + el.outerHeight() / 2
        return (ym2 - ym1) >= 0
    }
}

function preventEvent(e) {
    e.preventDefault()
    return false
}

function noop() {
}
